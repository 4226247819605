import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['sunburst']

    connect() {
        let sunburst_dom = $(this.sunburstTarget)
        let sunburst_data = sunburst_dom.get(0)
                                        .dataset
                                        .sunburst
        let ids = sunburst_dom.get(0)
                              .dataset
                              .ids
        this.dom_id = sunburst_dom.attr('id')

        this.plot_sunburst(sunburst_data, ids)
    }

    resize_sunburst(event) {
        relayout(this.dom_id);
    }

    plot_sunburst(sunburst_data, ids) {
        sunburst_data = JSON.parse(sunburst_data)

        sunburst(this.dom_id,
                 sunburst_data,
                 { filename: 'test', domain: 'test', cuvee: 'test'});
    }
}